module.exports = [{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com","https://fonts.cdnfonts.com"],"web":[{"name":"Mulish","file":"https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;700&display=swap"},{"name":"Back to Black Demo","file":"https://fonts.cdnfonts.com/css/back-to-black-demo"},{"name":"Amaranth","file":"https://fonts.googleapis.com/css2?family=Amaranth:wght@400;700&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"60644cb3d3cc40617e6c62560b85fde1"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
